import React from 'react'

// Components
import Layout from 'components/Layout'

const NotFoundPage = () => (
  <Layout subFooter>
    <div className="container">
      <h1 className="font-weight-xl mb-0 color-text-contrast pt-5">404</h1>
      <p className="font-weight-xl pb-5">Pagina niet gevonden.</p>
    </div>
  </Layout>
)

export default NotFoundPage
